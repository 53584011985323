import gigapixel from '_schema/gigapixel'

export const imageGigapixel = (photoEndpoint, thumbEndpoint, anchor = true) => {
  let b = {
    name: 'imageGigapixel',
    label: 'Blok z obrazem Gigapixel',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'tinymce',
          },
          alt: {},
        },
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoEndpoint,
        validate: ['maxSize'],
        thumbs: thumbEndpoint && {
          endpoint: thumbEndpoint,
        },
        ofmEnable: true,
      },
      blockPosition: {
        type: 'choice',
        choices: {
          left: 'T_GENERAL_BLOCK_POSITION_LEFT',
          left_vertical: 'T_GENERAL_BLOCK_POSITION_LEFT_VERTICAL',
          right: 'T_GENERAL_BLOCK_POSITION_RIGHT',
          full: 'T_GENERAL_BLOCK_POSITION_FULL',
        },
        validate: ['required'],
      },
      gigapixel: {
        type: 'resource',
        label: 'Obraz Gigapixel',
        endpoint: `${gigapixel.endpoint}?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
      },
      anchor: {
        validate: ['letters'],
      },
    },
  }

  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
