import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

export const textNews = () => ({
  name: 'textNews',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_NEWS',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          hint: 'T_GENERAL_BLOCK_TYPE_TEXT_NEWS_HINT',
        },
      },
    },
    automatic: {
      label: 'Automatycznie dobieraj najnowsze aktualnosci',
    },
    elements: {
      type: 'collection',
      endpoint: '/api/block_elements',
      definition: 'BlockElement-blockElement.read',
      description: 'T_GENERAL_BLOCK_TYPE_TEXT_NEWS_ADD',
      additionalProperties: {
        $ref:
          '#/definitions/BlockElement-blockElement.write_blockElement.create',
      },
      titleAccessor: function(resource, handleSuccess) {
        if (resource['@titleAccessor']) return resource['@titleAccessor']
        const setResource = res => {
          resource['@titleAccessor'] =
            res.translations && res.translations[process.env.REACT_APP_LOCALE]
              ? res.translations[process.env.REACT_APP_LOCALE].title ?? '--'
              : '--'
          if (handleSuccess) {
            handleSuccess(resource)
          }
        }
        if (resource.page) {
          fetchDataHandleAuthError(resource.page, 'GET', null, setResource)
        } else {
          return '--' //translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
        }
      },
      properties: {
        page: {
          type: 'resource',
          endpoint: `/api/pages_form?pagination=false&pageType=news&exists[versionable]=false`,
          titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
          label: 'T_BLOCK_ELEMENT_LABEL_PAGE',
          storeCollectionId: 'pages_form_type_news',
        },
        stat: {},
      },
    },
    anchor: {
      validate: ['letters'],
    },
  },
})
