export const textWithBackground = (
  photoEndpoint,
  thumbEndpoint,
  anchor = true
) => {
  let b = {
    name: 'textWithBackground',
    label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_BG',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'tinymce',
          },
          author: {},
          alt: {},
          buttonTitle: {},
          buttonLink: {},
        },
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoEndpoint,
        validate: ['maxSize'],
        thumbs: thumbEndpoint && {
          endpoint: thumbEndpoint,
        },
        ofmEnable: true,
      },
      anchor: {
        validate: ['letters'],
      },
    },
  }

  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
