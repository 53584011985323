export const textWithImage = (photoEndpoint, thumbEndpoint = null) => ({
  name: 'textWithImage',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_IMAGE',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          type: 'tinymcewithfootnotes',
        },
        text2: {
          type: 'string',
          label: 'Tytuł zdjęcia',
        },
        author: {},
        alt: {},
        buttonTitle: {},
        buttonLink: {},
      },
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      validate: ['maxSize'],
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
    },
    anchor: {
      validate: ['letters'],
    },
  },
})
