
export const textNews = () => ({
  name: 'textNews',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_NEWS',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          hint: 'T_GENERAL_BLOCK_TYPE_TEXT_NEWS_HINT'
        },
      },
    },
    elements: {
      type: 'collection',
      description: 'T_GENERAL_BLOCK_TYPE_TEXT_NEWS_ADD',
      endpoint: '/api/happen_block_elements',
      definition: 'HappenBlockElement-happenBlockElement.read',
      additionalProperties: {
        '$ref':'#/definitions/HappenBlockElement-happenBlockElement.write_happenBlockElement.create',
      },
      properties: {
        page: {
          type: 'resource',
          endpoint: `/api/pages_form?pagination=false&pageType=news&exists[versionable]=false`,
          titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,//'uuid',
          label: 'T_BLOCK_ELEMENT_LABEL_PAGE',
          storeCollectionId: 'pages_form_type_news',
        },
        stat: {}
      },
    },
    anchor: {
      validate: ['letters'],
    },
  },
})
