export const textVisit2 = (anchor = true) => {
  let b = {
    name: 'textVisit2',
    label: 'T_GENERAL_BLOCK_TYPE_TEXT_VISIT2',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            hint: 'T_GENERAL_BLOCK_TYPE_TEXT_TITLE_HINT',
          },
        },
      },
      anchor: {
        validate: ['letters'],
      },
    },
  }

  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
