import formsetSchema from '_schema/formset'

export const formsetBlock = () => ({
  name: 'formsetBlock',
  label: 'Blok z formularzem',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {
          label: 'Nazwa formularza',
        },
        text: {
          type: 'textarea',
          label: 'Opis formularza',
        },
      },
    },
    formset: {
      type: 'resource',
      endpoint: `${formsetSchema.endpoint}?pagination=false`,
      titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
    },
    anchor: {
      validate: ['letters'],
    },
  },
})
