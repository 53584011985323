export const anchorBlock = () => ({
  name: 'anchor',
  label: 'Blok "Spis treści"',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          type: 'textarea',
        },
      },
    },
    elements: {
      type: 'collection',
      description: 'Spis treści',
      endpoint: '/api/pasaz_block_elements',
      definition: 'PasazBlockElement-pasazBlockElement.read',
      statable: true,
      additionalProperties: {
        $ref:
          '#/definitions/PasazBlockElement-pasazBlockElement.write_pasazBlockElement.create',
      },
      titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
          },
        },
        anchor: {
          validate: ['letters'],
        },
        stat: {},
      },
    },
  },
})
