export const clubBlock = () => ({
  name: 'clubBlock',
  label: 'Blok "Klub koronki/haftu"',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    anchor: {
      validate: ['letters'],
    },
  },
})
