import packageFileSchema from 'core/_schema/packageFile'
import fileSchema from 'core/_schema/file'

export const packageFile = (anchor = true) => {
  let b = {
    name: 'packageFile',
    label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_FILE',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'tinymce',
          },
        },
      },
      packageFile: {
        type: 'resource',
        endpoint: `${packageFileSchema.endpoint}?pagination=false&embeded=false`,
        titleAccessor: 'title',
      },

      packageFileEmbeded: {
        type: 'collectionpackageembeded',
        endpoint: '/api/package_files',
        definition: 'PackageFile-packageFile.read',
        description: 'lub załącz pliki',
        dialogTitle: 'Pliki',
        titleAccessor: 'title',
        additionalProperties: {
          $ref:
            '#/definitions/PackageFile-packageFile.write_packageFile.create',
        },

        properties: {
          items: {
            type: 'embeddedcollection',
            endpoint: '/api/package_file_items',
            definition: 'PackageFileItem-packageFileItem.read',
            description: '',
            statable: true,
            additionalProperties: {
              $ref:
                '#/definitions/PackageFileItem-packageFileItem.write_packageFileItem.create',
            },

            titleAccessor: function(resource, handleSuccess) {
              if (resource.translations[process.env.REACT_APP_LOCALE].title) {
                return resource.translations[process.env.REACT_APP_LOCALE].title
              } else {
                return '--' //translate('T_GENERAL_MISSING_TITLE').replace('<','‹ ').replace('>',' ›')
              }
            },

            properties: {
              translations: {
                type: 'translation',
                properties: {
                  title: {},
                },
              },
              file: {
                type: 'file',
                description: 'T_GENERAL_FILE',
                endpoint: fileSchema.endpoint.single,
                validate: ['maxSize'],
                ofmEnable: true,
              },
            },
          },
        },
      },
    },
  }

  if (anchor) {
    b = {
      ...b,
      properties: {
        ...b.properties,
        anchor: {
          validate: ['letters'],
        },
      },
    }
  }
  return b
}
