import _block from './_block'
import photoSchema from './photo'
import fileSchema from './file'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { schema as schematag } from '_schema/tagEntry'

const schemaItem = {
  type: 'collection',
  endpoint: '/api/page_section_images',
  definition: 'PageSectionImage-pageSectionImage.read',
  description: 'T_PAGE_SECTION_IMAGES',
  additionalProperties: {
    $ref:
      '#/definitions/PageSectionImage-pageSectionImage.write_pageSectionImage.create',
  },
  titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        subtitle: { label: 'Podtytuł' },
        titleHint: { label: 'Tytuł hinta' },
        textHint: { label: 'Tekst hinta', type: 'textarea' },
        alt: {
          description: 'T_GENERAL_ALT',
        },
      },
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_MEDIA_PHOTO',
      endpoint: photoSchema.endpoint.single,
      endpointMultiple: photoSchema.endpoint.multiple,
      thumbs: {
        endpoint: '/api/page_section_image_thumbs',
      },
      validate: ['maxSize'],
      ofmEnable: true,
    },
    stat: {
      description: 'T_GENERAL_PUBLICATION',
    },
  },
}

const schema = {
  endpoint: '/api/pages',
  resourceAdd: {
    definition: 'Page-page.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
    },
  },
  resource: {
    definition: 'Page-page.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          subtitle: {},
          shortLead: {
            type: 'textarea',
          },
          text: {
            type: 'tinymce',
          },
          photoAlt: {},
          photoDesc: { type: 'textarea' },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/:slug',
              en: '/en/:slug',
            },
          },
          preview: {
            label: 'Podgląd',
            description: '',
            type: 'preview',
            routes: {
              pl: '/:slug',
              en: '/en/:slug',
            },
          },
        },
      },

      tags: {
        type: 'collection',
        endpoint: '/api/page_tags',
        definition: 'PageTag-pageTag.read',
        description: 'T_PAGE_TAGS',
        additionalProperties: {
          $ref: '#/definitions/PageTag-pageTag.write_pageTag.create',
        },
        titleAccessor: function(resource, handleSuccess) {
          if (resource['@titleAccessor']) return resource['@titleAccessor']
          const setResource = res => {
            resource['@titleAccessor'] =
              res.translations && res.translations[process.env.REACT_APP_LOCALE]
                ? res.translations[process.env.REACT_APP_LOCALE].title ?? '--'
                : '--'
            if (handleSuccess) {
              handleSuccess(resource)
            }
          }
          if (resource.tag) {
            fetchDataHandleAuthError(resource.tag, 'GET', null, setResource)
          } else {
            return '--'
          }
        },
        properties: {
          tag: {
            type: 'resourcetag',
            endpoint: `/api/tag_entries?pagination=false`,
            titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.titleWithName`, //'uuid',
            storeCollectionId: schematag.extraStoreCollectionId,
            label: 'T_TAG_ENTRY',
          },
          stat: {},
        },
      },

      pageDate: {
        type: 'date',
      },
      archive: {},

      // type: {
      //   type: 'media[type]',
      // },
      // youtube: {
      //   type: 'media[youtube]',
      //   description: 'T_GENERAL_YOUTUBE_CODE',
      // },
      photo: {
        type: 'media[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        thumbs: {
          endpoint: '/api/page_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
        accept: '.jpg,.png,.jpeg,.gif,.webp',
        hint: 'wybierz tylko dozwolone typy jpg, jpeg, png, gif, webp',
        ofmEnable: true,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks?pagination=false',
      definition: 'PageBlock-pageBlock.read',
      types: _block(
        photoSchema.endpoint.single,
        '/api/page_block_thumbs',
        fileSchema.endpoint.single
      ).types,
    },

    item: {
      endpoint: '/api/page_sections',
      definition: 'PageSection-pageSection.read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: { type: 'textarea' },
            subtitle: {},
            text: { type: 'textarea' },
            btnTitle: {},
          },
        },
        items: schemaItem,
      },
    },
  },
}

export default schema
