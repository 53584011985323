export const textWithVideo = (
  photoEndpoint,
  thumbEndpoint,
  usePosition = true
) => {
  let blok = {
    name: 'textWithVideo',
    label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_VIDEO',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'tinymcewithfootnotes',
          },
          text2: {
            type: 'textarea',
            label: 'Transkrypcja',
          },
          alt: {},
          buttonTitle: {},
          buttonLink: {},
        },
      },
      youtube: {
        type: 'youtube',
        description: 'T_GENERAL_YOUTUBE_CODE',
      },
      audiodescriptionbutton: {
        hint: 'link',
      },
      signlanguagebutton: {
        hint: 'link',
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_VIDEO_MASK',
        endpoint: photoEndpoint,
        validate: ['maxSize'],
        thumbs: thumbEndpoint && {
          endpoint: thumbEndpoint,
        },
      },
      blockPosition: {
        type: 'choice',
        choices: {
          left: 'T_GENERAL_BLOCK_POSITION_LEFT',
          // right: 'T_GENERAL_BLOCK_POSITION_RIGHT', //celowo to jest wyłączone
          full: 'T_GENERAL_BLOCK_POSITION_FULL',
        },
        validate: ['required'],
      },
      anchor: {
        validate: ['letters'],
      },
    },
  }

  if (!usePosition) {
    delete blok.properties.blockPosition
  }

  return blok
}
