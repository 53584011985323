export const sketchfab = (anchor = true) => {
  let b = {
    name: 'sketchfab',
    label: 'Blok z Sketchfab',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          subtitle: {},
          text: {
            type: 'tinymce',
          },
          // buttonLink:{},
          alt: {},
        },
      },
      youtube: {
        type: 'textarea',
        label: 'Link do sketchfaba',
        hint:
          'np. https://sketchfab.com/models/dfba0e890f4d4182905c7699433dd9b1/embed',
      },
      anchor: {
        validate: ['letters'],
      },
    },
  }

  if (!anchor) {
    delete b.properties.anchor
  }
  return b
}
