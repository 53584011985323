export const textQuote = (anchor = true) => {
  let b = {
    name: 'textQuote',
    label: 'T_GENERAL_BLOCK_TYPE_TEXT_QUOTE',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'textarea',
          },
        },
      },
    },
  }

  if (anchor) {
    b = {
      ...b,
      properties: {
        ...b.properties,
        anchor: {
          validate: ['letters'],
        },
      },
    }
  }
  return b
}
