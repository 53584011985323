export const residenceBlock = () => ({
  name: 'residenceBlock',
  label: 'Blok rezydencja królewska - plan',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        // subtitle: {},
        text: {
          type: 'tinymce',
        },
      },
    },
    anchor: {
      validate: ['letters'],
    },
  },
})
