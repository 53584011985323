export const translations = {
  pl: {
    /* Inside application code */
    T_GENERAL_OPERATIONS: 'Operacje',
    T_GENERAL_EDIT: 'Edytuj',
    T_GENERAL_NEW: 'Dodaj',
    T_GENERAL_CLONE: 'Klonuj',
    T_GENERAL_CLONING: 'Klonuję...',
    T_GENERAL_VERSION_CREATE: 'Archiwizuj',
    T_GENERAL_VERSION_CREATING: 'Archiwizuję...',
    T_GENERAL_VERSION_RESTORE: 'Przywróć wersję',
    T_GENERAL_VERSION_RESTORING: 'Przywracam wersję...',
    T_GENERAL_VERSION_CREATED_AT: 'Wersja z dnia',
    T_GENERAL_VIEW: 'Pokaż',
    T_GENERAL_DELETE: 'Usuń',
    T_GENERAL_DELETE_CONFIRM: 'Czy napewno usunąć',
    T_GENERAL_DELETED: 'Rekord usunięty',
    T_GENERAL_CREATED_AT: 'Utworzono',
    T_GENERAL_UPDATED_AT: 'Ostatnia aktualizacja',
    T_GENERAL_CANCEL: 'Anuluj',
    T_GENERAL_CONFIRM: 'Potwierdź',
    T_GENERAL_STATUS_CHANGED: 'Zmieniono status',
    T_GENERAL_SAVE: 'Zapisz',
    T_GENERAL_SAVE_TO_KEEP_CHANGES: 'Zapisz aby zachować zmiany',
    T_GENERAL_SEARCH: 'Szukaj',
    T_GENERAL_ALL: 'Wszystkie',
    T_GENERAL_REFRESH: 'Odswież',
    T_GENERAL_TOGGLE_MENU: 'Przełącznik menu',
    T_GENERAL_RESULT_SUCCESS: 'Udane',
    T_GENERAL_RESULT_FAILURE: 'Nieudane',
    T_GENERAL_NO: 'Nr',
    T_GENERAL_OPERATION_DATE: 'Data operacji',
    T_GENERAL_MODULE: 'Moduł',
    T_GENERAL_RECORD: 'Rekord',
    T_GENERAL_OPERATION: 'Operacja',
    T_GENERAL_SHOW_ALL: 'Pokaż wszystkie',
    T_GENERAL_REQUIRED: 'Wymagany',
    T_GENERAL_DATE: 'Data',
    T_GENERAL_EMPTY: 'Brak',
    T_GENERAL_TITLE: 'Tytuł',
    T_GENERAL_INVALID_DATE: 'Niepoprawny format',
    T_GENERAL_INVALID_MIN_DATE: 'Data nie może być mniejsza niż 01.01.1990',
    T_GENERAL_INVALID_MAX_DATE: 'Data nie może być większa niż 01.01.2100',
    T_GENERAL_DESCRIPTION: 'Krótki opis',
    T_GENERAL_DESCRIPTION_HINT: 'Wyświetlany na detalu',
    T_GENERAL_LEAD: 'Lead',
    T_GENERAL_LEAD_HINT: 'Wyświetlany na liście',
    T_GENERAL_PUBLICATION: 'Publikacja',
    T_GENERAL_CODE: 'Kod',
    T_GENERAL_TIME: 'Czas',
    T_GENERAL_ADMIN: 'Administrator',
    T_GENERAL_RECORD_LIST: 'Lista',
    T_GENERAL_RECORD_NEW: 'Dodaj',
    T_GENERAL_RECORD_NEW_BUTTON: 'Dodaj nowy rekord',
    T_GENERAL_RECORD_EDIT: 'Edycja',
    T_GENERAL_RECORD_SHOW: 'Pokaż',
    T_GENERAL_RECORD_PREVIEW: 'Podgląd',
    T_GENERAL_RECORD_VERSION_COLLECTION: 'Poprzednie wersje',
    T_GENERAL_RECORD_VERSION_VIEW: 'Podgląd',
    T_GENERAL_ITEMS_EMPTY: 'Brak elementów',
    T_GENERAL_BLOCK: 'Bloki',
    T_GENERAL_BLOCK_TYPE_TEXT: 'Blok tekstowy',
    T_GENERAL_BLOCK_TYPE_TEXT_QUOTE: 'Blok z copy',
    T_GENERAL_BLOCK_TYPE_TEXT_QUOTE_SECTION: 'Blok z sekcją przejścia',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_VIDEO: 'Blok tekstowy z filmem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_AUDIO: 'Blok tekstowy z audio',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_IMAGE: 'Blok tekstowy ze zdjęciem',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FILE: 'Blok z plikami',
    T_GENERAL_BLOCK_TYPE_PACKAGE_GALLERY: 'Blok z galerią',
    T_GENERAL_BLOCK_GALLERY_TYPE_SLIDER: 'Slider',
    T_GENERAL_BLOCK_GALLERY_TYPE_TILE: 'Mozaika',
    T_GENERAL_CHOOSE: 'Wybierz',
    T_GENERAL_MISSING_TITLE: '<Brak tytułu>',
    T_GENERAL_SUPER_ADMIN: 'Super Admin',
    T_GENERAL_TRANSLATION: 'Tłumaczenie',
    T_GENERAL_MANUAL_ORDER: 'Manualna kolejność',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_PREVIOUS:
      'Upuść tu żeby przenieść na poprzednią stronę',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_NEXT:
      'Upuść tu żeby przenieść na następną stronę',
    T_GENERAL_DRAG_HANDLE: 'Przytrzymaj i przeciągnij, aby zmienić kolejność',
    T_GENERAL_UPLOAD: 'Upload pliku',
    T_GENERAL_UPLOAD_MULTIPLE: 'Upload plików',
    T_GENERAL_PHOTO: 'Zdjęcie',
    T_GENERAL_IMAGE_ADD: 'Dodaj zdjęcie',
    T_GENERAL_IMAGE_CHANGE: 'Wymień zdjęcie',
    T_GENERAL_IMAGE_REMOVE: 'Usuń zdjęcie',
    T_GENERAL_IMAGE_REMOVE_CONFIRM: 'Czy napewno usunąć zdjęcie?',
    T_GENERAL_PHOTO_THUMBS: 'Kadry',
    T_GENERAL_PHOTO_THUMBS_EMPTY: 'Brak kadrów',
    T_GENERAL_PHOTO_THUMBS_SAVE: 'Zapisz kadr',
    T_GENERAL_PHOTO_THUMBS_RESET: 'Resetuj kadr',
    T_GENERAL_PHOTO_THUMBS_CHANGE_FILE: 'Zmień plik dla kadru',
    T_GENERAL_EXPAND_ON: 'zwiń wszystkie',
    T_GENERAL_EXPAND_OFF: 'rozwiń wszystkie',
    T_GENERAL_EXPAND_BLOCKS: 'bloki',
    T_GENERAL_BLOCK_COLLECTION_TITLE: 'Bloki śródtekstowe',
    T_GENERAL_ALT: 'Znacznik alt zdjęcia',
    T_GENERAL_FILE: 'Plik',
    T_GENERAL_FILE_ADD: 'Dodaj plik',
    T_GENERAL_FILE_CHANGE: 'Wymień plik',
    T_GENERAL_FILE_REMOVE: 'Usuń plik',
    T_GENERAL_FILE_REMOVE_CONFIRM: 'Czy napewno usunąć plik?',
    T_GENERAL_NAME: 'Nazwa',
    T_GENERAL_SEND: 'Wyślij',
    T_GENERAL_CHOSEN_FILE: 'Wybrany plik',
    T_GENERAL_CHOSEN_FILES: 'Wybrane pliki',
    T_GENERAL_NONE: 'brak',
    T_GENERAL_YOUTUBE_CODE: 'Kod filmu youtube',
    T_GENERAL_VIDEO_MASK: 'Maska filmu',
    T_GENERAL_MEDIA_PHOTO: 'Zdjęcie',
    T_GENERAL_MEDIA_YOUTUBE: 'Film youtube',
    T_GENERAL_MASS_SELECT_ACTION: 'Co zrobić z zaznaczonymi rekordami',
    T_GENERAL_MASS_SELECT_CHOOSE: 'Wybierz',
    T_GENERAL_MASS_SELECT_PUBLISH: 'Publikuj',
    T_GENERAL_MASS_SELECT_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_ENABLE: 'Włącz',
    T_GENERAL_DISABLE: 'Wyłącz',
    T_GENERAL_OPENFORM_ADMIN_GROUP_IS_OPENFORM: '[OF] Prawa openform',
    T_GENERAL_OPENFORM_PAGE_IDNAME: '[OF] Id name',
    T_GENERAL_OPENFORM_PAGE_IS_STATABLE: '[OF] Publikowalna',
    T_GENERAL_OPENFORM_PAGE_IS_EDITABLE: '[OF] Edytowalna',
    T_GENERAL_OPENFORM_PAGE_IS_DELETABLE: '[OF] Usuwalna',
    T_GENERAL_OPENFORM_PAGE_IS_SUBPAGEABLE: '[OF] Podstrony',
    T_GENERAL_SAVE_FORM_TO_EDIT: 'Zapisz formularz, aby edytować',
    T_GENERAL_PASSWORD_WAS_RESETTED:
      'Twoje hasło zostało zresetowane przez administratora. Ustaw nowe hasło, a następnie przeloguj się, aby odzyskać dostęp.',
    T_GENERAL_EDIT_DESCRIPTION: 'edytuj opis',
    T_GENERAL_BACK_TO_LIST: 'Wróć na listę',
    T_GENERAL_NEXT: 'Następny',
    T_GENERAL_PREVIOUS: 'Poprzedni',
    T_GENERAL_FULL_SCREEN: 'Full screen',
    T_GENERAL_LOGIN: 'Zaloguj',
    T_GENERAL_LOGOUT: 'Wyloguj',
    T_GENERAL_REMEMBER_ME: 'Zapamiętaj mnie',
    T_GENERAL_FORGOT_PASSWORD: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TITLE: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TYPE_EMAIL: 'Twój adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_SEND_LINK: 'Wyślij link',
    T_GENERAL_FORGOT_PASSWORD_VALIDATION_EMAIL: 'Niepoprawny adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_LINK_SENT:
      'Link wysłany. Sprawdź swój adres e-mail',
    T_GENERAL_NEW_PASSWORD_TITLE: 'Nadaj nowe hasło',
    T_GENERAL_NEW_PASSWORD_TYPE_NEW: 'Wpisz nowe hasło',
    T_GENERAL_NEW_PASSWORD_RETYPE_NEW: 'Powtórz nowe hasło',
    T_GENERAL_NEW_PASSWORD_CHANGE: 'Zmień hasło',
    T_GENERAL_NEW_PASSWORD_RESETTED: 'Hasło zostało zmienione',
    T_GENERAL_BACK_TO_LOGIN: 'Powrót do logowania',
    T_GENERAL_MAP: 'Mapa',
    T_GENERAL_MAP_HINT:
      'Wpisz pełną lokalizację lub częściową i wybierz z listy. Marker na mapie zostanie automatycznie ustawiony',
    T_GENERAL_MAP_INPUT: 'Wpisz lokalizację',
    T_GENERAL_NORMAL_VIEW: 'Powrót do poprzedniego widoku',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_CHOSEN: 'Dodajesz % plików',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_INFO: 'Nadaj wszystkim dodawanym:',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING: 'Dodaję',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING_FROM: 'z',
    T_GENERAL_CONTEXTUAL_HINT: 'Pomoc kontekstowa',
    T_GENERAL_YOU_ARE_LOGGED_AS: 'Jesteś zalogowany jako',
    T_GENERAL_MASS_UPLOAD_DRAG_AND_DROP:
      'Przeciągnij i upuść lub kliknij, żeby wybrać',
    T_GENERAL_PLANNED_PUBLISH_DATE_TIME: 'Planowana publikacja',
    T_GENERAL_PUBLISH: 'Publikuj',
    T_GENERAL_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_VERSION: 'Wersja',
    T_GENERAL_DOWNLOAD_YOUTUBE_THUMBNAIL: 'Pobierz zaślepkę z youtube',
    T_GENERAL_LINK: 'Link',
    T_GENERAL_FRONT_ROUTE: 'Adres',
    T_GENERAL_AUDIO: 'Plik audio',
    T_GENERAL_EMAIL_SENT: 'E-mail wysłany',
    T_LOGIN_DATE: 'Data logowania',
    T_TABLE_FIRST_PAGE: 'Pierwsza strona',
    T_TABLE_LAST_PAGE: 'Ostatnia strona',
    T_TABLE_RESET: 'Wyczyść ustawienia tabeli',
    T_TABLE_NO_RESULTS: 'Brak wyników',
    T_MODULE_DASHBOARD: 'Dashboard',
    T_MODULE_SETTINGS: 'Ustawienia',
    T_MODULE_PROFILE: 'Profil',
    T_MODULE_PACKAGE_GALLERIES: 'Lista galerii',
    T_MODULE_PACKAGE_GALLERIES_NEW_BUTTON: 'Dodaj nową galerię',
    T_MODULE_PACKAGE_GALLERY_ITEMS: 'Lista mediów',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_BUTTON: 'Dodaj nowe zdjęcie',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_MULTIPLE_BUTTON: 'Dodaj wiele zdjęć',
    T_MODULE_PACKAGE_FILES: 'Lista pakietów plików',
    T_MODULE_PACKAGE_FILES_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_MODULE_TRANSLATOR_ENTRIES: 'Lista tłumaczeń',
    T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON: 'Dodaj nowe tłumaczenie',
    T_MODULE_ADMINS: 'Lista administratorów',
    T_MODULE_ADMINS_NEW_BUTTON: 'Dodaj nowego administratora',
    T_MODULE_ADMIN_GROUPS: 'Lista grup administratorskich',
    T_MODULE_ADMIN_GROUPS_NEW_BUTTON: 'Dodaj nową grupę administratorską',
    T_MODULE_PAGES: 'Lista stron',
    T_MODULE_PAGES_NEW_BUTTON: 'Dodaj nową stronę',
    T_MODULE_PAGES_EDIT_STRUCTURE: 'Edycja struktury',
    T_MODULE_PAGES_SHOW_ALL_CHILDREN: 'Rozwiń wszystko',
    T_MODULE_PAGES_HIDE_ALL_CHILDREN: 'Zwiń wszystko',
    T_MODULE_PAGES_ADD_SUBPAGE: 'Dodaj podstronę',
    T_MODULE_PAGES_SUBPAGE_UNDER_PREVIOUS_PAGE:
      'Przenieś jako podstronę strony wyżej',
    T_MODULE_PACKAGE_FILE_ITEMS_HEADER_TITLE: 'Pliki:',
    T_MODULE_PACKAGE_FILE_ITEMS_EXPAND_TITLE: 'pliki',
    T_MODULE_MESSENGER: 'Wiadomości',
    T_MODULE_MESSENGER_RECIPIENT: 'Odbiorca',
    T_MODULE_MESSENGER_SEND_TO: 'Napisz do',
    T_MODULE_MESSENGER_GO_FURTHER: 'Przejdź dalej',
    T_MODULE_MESSENGER_THREAD: 'Wątek',
    T_MODULE_MESSENGER_MESSAGE: 'Wiadomość',
    T_MODULE_MESSENGER_WITH: 'z',
    T_MODULE_MESSENGER_SENT: 'Wiadomość wysłana',
    T_MODULE_MESSENGER_MORE: 'Więcej',
    T_MODULE_MESSENGER_UPDATED_AT: 'Aktualizacja',
    T_MODULE_MESSENGER_YOU: 'Ty',
    T_MODULE_CONFIG_DEFAULT_FACEBOOK_PHOTO: 'Domyślne zdjęcie na Facebooka',
    T_MENU_PACKAGES: 'Pakiety',
    T_MENU_REGISTRIES: 'Rejestry',
    T_REQUEST_METHOD_GET: 'Wyświetlenie',
    T_REQUEST_METHOD_PATCH: 'Edycja',
    T_REQUEST_METHOD_PUT: 'Edycja',
    T_REQUEST_METHOD_POST: 'Utworzenie',
    T_REQUEST_METHOD_DELETE: 'Usunięcie',
    T_FORM_PROFILE_TITLE: 'Dane',
    T_FORM_CHANGE_PASSWORD_TITLE: 'Zmiana hasła',
    T_FORM_FIELD_EMAIL: 'E-mail',
    T_FORM_FIELD_PASSWORD: 'Hasło',
    T_FORM_FIELD_NEW_PASSWORD: 'Nowe hasło',
    T_FORM_FIELD_NEW_PASSWORD_REPEAT: 'Powtórz nowe hasło',
    T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD: 'Podaj nowe hasło',
    T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD: 'Powtórz nowe hasło',
    T_FORM_FIELD_RESOURCE_ACCESS: 'Dostęp do modułów',
    T_FORM_FIELD_BOOLEAN_ON: 'Tak',
    T_FORM_FIELD_BOOLEAN_OFF: 'Nie',
    T_FORM_INCORRECT: 'Niepoprawny formularz',
    T_FORM_SUCCESS: 'Zapisano',
    T_FORM_RECORD_UPDATED: 'Rekord zaktualizowany',
    T_FORM_RECORD_CREATED: 'Rekord dodany',
    T_FORM_RECORD_CLONED: 'Rekord sklonowany',
    T_FORM_RECORD_MULTIPLE_CREATED: 'Rekordy dodane',
    T_FORM_RECORD_MULTIPLE_UPDATED: 'Rekordy zaktaulizowane',
    T_FORM_RECORD_UPLOADED: 'Rekord wgrany',
    T_FORM_RECORD_VERSION_CREATED: 'Wersja rekordu utworzona',
    T_FORM_RECORD_VERSION_RESTORED: 'Wersja rekordu przywrócona',
    T_FORM_CONTEXTUAL_HINTS_ON: 'Pomoc kontekstowa włączona',
    T_FORM_CONTEXTUAL_HINTS_OFF: 'Pomoc kontekstowa wyłączona',
    T_FORM_SAVE: 'Zapisz',
    T_FORM_SAVE_AND_STAY: 'Zapisz i pozostań',
    T_FORM_ACCEPT: 'Akceptuj',
    T_FORM_CANCEL: 'Anuluj',
    T_FORM_RECORD_CHANGED:
      'Rekord został zmieniony przez innego administratora. Sprawdź zmiany i zaakceptuj.',
    T_VALIDATION_REQUIRED: 'Pole wymagane',
    T_VALIDATION_EMAIL: 'Niepoprawny e-mail',
    T_VALIDATION_PHONE: 'Niepoprawny numer telefonu',
    T_VALIDATION_PASSWORD:
      'Hasło musi składać się z min. 8 znaków (duża litera, mała litera, cyfra, znak specjalny)',
    T_VALIDATION_PASSWORDS_DO_NOT_MATCH: 'Hasła nie zgadzają się',
    T_VALIDATION_LENGTH: 'To pole musi się składać z min. % znaków',
    T_VALIDATION_MAX_UPLOAD_SIZE: 'Maksymalny rozmiar pliku to %',
    T_VALIDATION_MAX_UPLOAD_MULTIPLE_SIZE:
      'Maksymalny rozmiar wszystkich plików to %',
    T_VALIDATION_MIN_VALUE: 'Minimalna wartość to %',
    T_VALIDATION_MAX_VALUE: 'Maksymalna wartość to %',
    T_404_TITLE: 'Strona nie istnieje',
    T_404_TEXT:
      'Wygląda na to, że kliknąłeś niedziałający link lub wprowadziłeś adres URL, który nie istnieje na tej witrynie.',
    T_404_BACK: 'Powrót na stronę główną',
    T_SETTINGS_MODE_LIGHT: 'Przejdź na tryb jasny',
    T_SETTINGS_MODE_DARK: 'Przejdź na tryb ciemny',
    T_FRONT_PREVIEW_ERROR_TITLE: 'Błąd',
    T_FRONT_PREVIEW_ERROR_TEXT: 'Błąd przy pobieraniu strony frontowej',
    /* Thumbs */
    'T_THUMB_API/PAGE_THUMBS_MAIN-SM': 'Kadr główny mobile',
    'T_THUMB_API/PAGE_THUMBS_MAIN': 'Kadr główny',
    'T_THUMB_API/PAGE_THUMBS_LIST': 'Kadr na listę',
    'T_THUMB_API/PAGE_THUMBS_CAFEL': 'Kafel sekcji wiedza online',
    'T_THUMB_API/HAPPEN_THUMBS_DZIEJESIE' : 'baner strony Dzieje Się',

    'T_THUMB_API/PAGE_BLOCK_THUMBS_MAIN': 'Kadr główny (blok ze zdjęciem/filmem)',
    'T_THUMB_API/PAGE_BLOCK_THUMBS_VERTICAL': 'Kadr pionowy (blok ze zdjęciem)',
    'T_THUMB_API/PAGE_BLOCK_THUMBS_MAIN2':
      'Kadr poziomy - blok z sekcją przejścia',
    'T_THUMB_API/PAGE_BLOCK_THUMBS_FULLHD':
      'Zdjęcia pełna szerokość strony (bloki ze zdjęciem/filmem) - fullHD',
    'T_THUMB_API/PAGE_BLOCK_THUMBS_FULL':
      'Zdjęcia pełna szerokość strony (bloki ze zdjęciem/filmem)',
    'T_THUMB_API/PAGE_BLOCK_THUMBS_FULLSM':
      'Zdjęcia pełna szerokość strony (bloki ze zdjęciem/filmem) - małe ekrany',

    'T_THUMB_API/PASAZ_BLOCK_THUMBS_MAIN': 'Kadr główny (blok ze zdjęciem/filmem)',

    'T_THUMB_API/EXHIBITION_BLOCK_THUMBS_MAIN':
      'Kadr główny (blok ze zdjęciem/filmem)',
    'T_THUMB_API/EXHIBITION_BLOCK_THUMBS_VERTICAL':
      'Kadr pionowy (blok ze zdjęciem)',
    'T_THUMB_API/EXHIBITION_BLOCK_THUMBS_MAIN2':
      'Kadr poziomy - blok z sekcją przejścia',
    'T_THUMB_API/EXHIBITION_BLOCK_THUMBS_FULLHD':
      'Zdjęcia pełna szerokość strony (bloki ze zdjęciem/filmem) - fullHD',
    'T_THUMB_API/EXHIBITION_BLOCK_THUMBS_FULL':
      'Zdjęcia pełna szerokość strony (bloki ze zdjęciem/filmem)',
    'T_THUMB_API/EXHIBITION_BLOCK_THUMBS_FULLSM':
      'Zdjęcia pełna szerokość strony (bloki ze zdjęciem/filmem) - małe ekrany',

    'T_THUMB_API/PAGE_THUMBS_DETAIL': 'Kadr detal artykułu',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_LIST': 'Kadr',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_LISTH': 'Kadr pionowy',
    'T_THUMB_API/HOME_BANNER_ITEM_THUMBS_MAIN': 'Kadr główny',

    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_GRIDVERTICAL':
      'Miniatura pionowa w siatce',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_GRIDHORIZONTAL':
      'Miniatura pozioma w siatce',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_CAROUSEL':
      'Miniatura zdjęcia w karuzelii',

    'T_THUMB_API/NEWSLETTER_BLOCK_THUMBS_VERTICAL': 'Kadr pionowy',
    'T_THUMB_API/NEWSLETTER_BLOCK_THUMBS_FULL': 'Kadr poziomy',

    'T_THUMB_API/RESIDENCE_ITEM_THUMBS_VERTICAL': 'Kadr główny',

    /* Returned by API: modules */
    Administrators: 'Administratorzy',
    'Administrator Groups': 'Grupy administratorskie',
    'Registry entries': 'Rejestr zmian',
    'Login entries': 'Historia logowania',
    Pages: 'Struktura stron',
    'Page blocks': 'Bloki strony',
    'Page block thumbs': 'Kadry bloku strony',
    'File packages': 'Pliki',
    'File package items': 'Elementy pakietu plików',
    'Gallery packages': 'Galerie',
    'Gallery package items': 'Elementy galerii',
    'Gallery package item thumbs': 'Kadry elementu galerii',
    'Translator entries': 'Tłumaczenia',
    Config: 'Konfiguracja',
    'Home banner items': 'Główny baner',
    /* Returned by API: fields */
    'Name and surname': 'Imię i nazwisko',
    Group: 'Grupa',
    'Is enabled': 'Aktywny',
    Title: 'Tytuł',
    Username: 'Nazwa użytkownika',
    'Origin ip': 'IP',
    Result: 'Status',
    'Is super admin': 'Prawa super admina',
    'Is moderator': 'Prawa moderatora',
    'Title of article': 'Tytuł artykułu',
    'Text of article': 'Zawartość artykułu',
    'Show in main menu': 'Pokaż w menu głównym',
    Text: 'Tekst główny',
    'Meta title': '[SEO] Meta tytuł',
    'Meta description': '[SEO] Meta opis',
    'Meta keywords': '[SEO] Meta słowa kluczowe',
    Slug: 'Slug (generowany automatycznie z tytułu)',
    'Code of translator entry': 'Kod tłumaczenia',
    'Title of translator entry': 'Tłumaczenie',
    Question: 'Pytanie',
    Answer: 'Odpowiedź',
    Name: 'Nazwa',
    'Type of element': 'Typ elementu',
    'Is required': 'Wymagany do uzupełnienia',
    Description: 'Opis',
    Subtitle: 'Podtytuł',
    Quotation: 'Cytat',
    'Button title': 'Tytuł linka',
    'Button link': 'Link',
    'File package': 'Pakiet plików',
    'Gallery package': 'Pakiet galerii',
    Sections: 'Sekcje',
    'Text before media': 'Tekst przed zdjęciem',
    'Text after media': 'Tekst po zdjęciu',
    'Item type': 'Typ medium',
    'Media position': 'Pozycja zdjęcia',
    'Gallery type': 'Typ galerii',
    'Photo count': 'Zdjęć',
    'Video count': 'Filmów',
    'Media type': 'Typ medium',
    Photographer: 'Fotograf',
    Column: 'Kolumna',
    'Contact text': 'Tekst kontaktu w stopce',
    'Link to facebook': 'Link do profilu Facebooka',
    'Link to instagram': 'Link do profilu Instagrama',
    'Additional head code': 'Dodatkowy kod sekcji HTML HEAD',
    'Additional body code': 'Dodatkowy kod sekcji HTML BODY',
    Photo: 'Zdjęcie',
    'Photo: title': 'Zdjęcie: tytuł',
    'Photo: author': 'Zdjęcie: autor',
    'Photo: alt': 'Zdjęcie: znacznik alt',
    /* Returned by API: general */
    'Your current password is incorrect.': 'Podałeś niepoprawne hasło',
    'Bad credentials.': 'Niepoprawne dane',
    Error: 'Błąd',
    TypeError: 'Błąd',
    'An error occurred': 'Błąd',
    'Not Found': 'Rekord nie odnaleziony',
    'Syntax error': 'Błąd składni. Skontaktuj się z administratorem',
    'This value is already used.': 'Ta wartość jest już używana',
    'This value should not be null.': 'Pole wymagane',
    Forbidden: 'Brak dostępu do tego zasobu',
    Unauthorized: 'Dostęp zablokowany',
    'Internal Server Error': 'Wystąpił błąd. Skontaktuj się z administratorem',
    'TypeError: Failed to fetch':
      'Błąd połączenia z serwerem. Skontaktuj się z administratorem',
    'Password is common.': 'Wybrałeś znane hasło',
    'You have already used that password.': 'Hasło zostało już wcześniej użyte',
    'Login blocked for 15 mins.': 'Logowanie zablokowane na 15 minut',
    'Circular parentage detected. Current entity or current entity descendants cannot be set as a parent to current entity.':
      'Niepoprawna zmiana rodzica. Nie można przenieść strony w dół aktualnego drzewa.',
    'Parent page is not subpageable.':
      'Niepoprawna zmiana rodzica. Rodzic nie może posiadać podstron.',
    'Nesting level exceeded.':
      'Niepoprawna zmiana rodzica. Osiągnięto maksymalny poziom zagnieżdżenia stron.',
    'Youtube thumbnail does not exist.': 'Zaślepka nie istnieje',
    'Admin does not exist.': 'Administrator nie istnieje.',
    'Reset password link has been already generated.':
      'Link do zresetowania hasła został już wygenerowany.',
    'Incorrect hash.': 'Niepoprawny link.',
    'Link expired.': 'Wygasły link.',
    'Reset password blocked for 15 mins.':
      'Resetowanie hasła zablokowane na 15 minut',

    T_MODULE_HOME_BANNER: 'Główny baner',
    T_MODULE_HOME_BANNER_NEW_BUTTON: 'Dodaj nowy slide',
    'Alert message': '[KOMUNIKAT] Wiadomość',
    'Alert link': '[KOMUNIKAT] Link',
    T_MODULE_CONFIG_ALETMESSAGE_HINT: 'komunikat pojawi się, jeśli wpisany',
    T_MODULE_CONFIG_ALERTLINK_HINT: 'guzik pojawi się, jeśli wpisany link',

    'Tag entries': 'Tagi',
    T_MODULE_TAG_ENTRIES: 'Tagi',
    T_MODULE_TAG_ENTRIES_NEW_BUTTON: 'Dodaj nowy tag',

    'Logotype packages': 'Logotypy',
    'Logotype package': 'Logotypy',
    'Link logotype': 'Link po kliknięciu w logotyp',
    T_MODULE_PACKAGE_LOGOTYPES_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_MODULE_PACKAGE_LOGOTYPE: 'Lista pakietów logotypów',

    'Faq packages': 'FAQ',
    T_MODULE_PACKAGE_FAQS: 'Lista pakietów pytań',
    T_MODULE_PACKAGE_FAQ_ITEMS_EXPAND_TITLE: 'pytania',
    T_MODULE_PACKAGE_FAQ_ITEMS_HEADER_TITLE: 'Pytania',
    T_MODULE_PACKAGE_FAQS_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_GENERAL_FAQ: 'Pytanie',

    'Faq package': 'Pakiet FAQ',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FAQ: 'Blok z FAQ',

    'Related elements': 'Powiązane atykuły',
    'Title of element': 'Tytuł',
    T_BLOCK_ELEMENT_LABEL_PAGE: 'Wybierz stronę',
    T_GENERAL_BLOCK_TYPE_TEXT_ELEMENTS: 'Blok z artykułami',

    T_GENERAL_BLOCK_TYPE_PACKAGE_LOGOTYPE: 'Blok z logotypami',
    T_GENERAL_BLOCK_TYPE_TEXT_LOGOTYPES_ADD: 'Dodaj pakiety logotypów',

    T_GENERAL_BLOCK_TYPE_TEXT_LINKS: 'Blok z linkami',
    T_GENERAL_BLOCK_TYPE_TEXT_LINKS_ADD: 'Dodaj linki',

    'Show audiodescription button': 'Audiodeskrypcja',
    'Show sign language button': 'Tłumacz migowy',

    'Block position': 'Pozycja bloku',
    T_GENERAL_BLOCK_POSITION_RIGHT: 'pionowe zdjęcie z prawej',
    T_GENERAL_BLOCK_POSITION_LEFT: 'poziome zdjęcie',
    T_GENERAL_BLOCK_POSITION_LEFT_VERTICAL: 'pionowe zdjęcie z lewej',
    T_GENERAL_BLOCK_POSITION_FULL: 'pełna szerokość',
    Alt: 'Znacznik alt zdjęcia',

    T_GENERAL_BLOCK_TYPE_TEXT_COLUMN: 'Blok z dwoma kolumnami',
    T_GENERAL_BLOCK_TYPE_TEXT_COLUMN_ADD: 'Elementy bloku',
    T_GENERAL_BLOCK_TYPE_TEXT_TITLE_HINT:
      'tytuł tylko widoczny w panelu administracyjnym',

    T_GENERAL_BLOCK_TYPE_TEXT_NEWS: 'Blok z aktualnościami',
    T_GENERAL_BLOCK_TYPE_TEXT_NEWS_HINT: 'domyślnie tłumaczenie "Aktualności"',
    T_GENERAL_BLOCK_TYPE_TEXT_NEWS_ADD: 'Elementy bloku',

    T_GENERAL_BLOCK_TYPE_TEXT_VISIT: 'Blok "Rózne możliwości wizyty"',
    T_GENERAL_BLOCK_TYPE_TEXT_VISIT2: 'Blok "Odwiedź nas" (slider)',

    T_GENERAL_BLOCK_TYPE_TEXT_WITH_BG: 'Blok ze zdjęciem w tle',

    'Person items': 'Osoby prowadzące',
    T_MODULE_PERSON: 'Osoby prowadzące',
    T_MODULE_PERSON_NEW_BUTTON: 'Dodaj osobę',

    T_GENERAL_BLOCK_TYPE_TEXT_PERSON: 'Blok z osobami',
    T_GENERAL_BLOCK_TYPE_TEXT_PERSON_ADD: 'Osoby prowadzące',
    T_GENERAL_BLOCK_TYPE_TEXT_PERSON_TITLE_HINT:
      'można wpisać aby zastąpić imię i nazwisko prowadzącego w bloku',
    T_BLOCK_ELEMENT_LABEL_PERSON: 'Wybierz osobę',
    T_GENERAL_BLOCK_TYPE_CAFEBLOCK: 'Blok "Kawiarnia" ze strony zwiedzaj',

    T_MODULE_CREWS_ADD_SUBPAGE: 'Dodaj pod dział',
    T_MODULE_CREWS_NEW_BUTTON: 'Dodaj nowy dział',
    T_MODULE_CREWS: 'Zespół - działy',
    Crews: 'Zespół',
    T_CREW_JOB: 'Funkcja osoby',
    T_CREW_EXTRAINFO: 'Dodatkowy przypis',
    T_CREW_EXTRAINFO_HINT: 'np. (czynny w godz. 16.00–21.00), itp.',
    T_MODULE_CREW_ITEMS_HEADER_TITLE: 'Przypisane osoby',
    T_MODULE_CREW_ITEMS_EXPAND_TITLE: 'osoby',
    T_GENERAL_CREW_ITEMS: 'Osoba',
    Phone: 'Telefon',

    T_GENERAL_BLOCK_TYPE_TEXT_CREW: 'Blok z osobami z zespołu',
    T_GENERAL_BLOCK_TYPE_TEXT_CREW_ADD: 'Osoby',
    T_GENERAL_BLOCK_TYPE_TEXT_CREW_TITLE_HINT:
      'można wpisać aby zastąpić imię i nazwisko osoby w bloku',
    T_BLOCK_ELEMENT_LABEL_CREW: 'Wybierz osobę',

    News: 'Aktualności',
    T_LIST: 'Lista',
    T_MODULE_NEWS: 'Aktualności',
    T_MODULE_NEWS_NEW_BUTTON: 'Dodaj aktualność',
    T_NEWS_TAGS: 'Tagi aktualności',
    T_HAPPEN_TAGS: 'Tagi wydarzeń',
    T_PAGE_TAGS: 'Tagi na stronie',
    T_TAG_ENTRY: 'Tag',
    'Page date': 'Data na stronie',
    'Title of tag entry': 'Tytuł taga',
    'Short lead': 'Krótki opis',
    'Event date': 'Data wydarzenia',

    Happens: 'Wydarzenia',
    T_MODULE_HAPPENS: 'Wydarzenia',
    T_MODULE_HAPPENS_NEW_BUTTON: 'Dodaj wydarzenie',
    'T_THUMB_API/HAPPEN_THUMBS_MAIN': 'Kadr główny',
    'T_THUMB_API/HAPPEN_THUMBS_LIST': 'Kadr na listę',
    'T_THUMB_API/HAPPEN_THUMBS_DETAIL': 'Kadr do detalu',
    'T_THUMB_API/HAPPEN_THUMBS_VERTICAL': 'Kadr do baneru w sklepie',

    Exhibitions: 'Wystawy',
    T_MODULE_EXHIBITIONS: 'Wystawy',
    T_MODULE_EXHIBITIONS_NEW_BUTTON: 'Dodaj wystawę',
    'T_THUMB_API/EXHIBITION_THUMBS_MAIN': 'Kadr główny',
    'T_THUMB_API/EXHIBITION_THUMBS_SMALL': 'Kadr główny - mobile',
    'T_THUMB_API/EXHIBITION_THUMBS_BIG': 'Kadr główny - duże ekrany',
    'T_THUMB_API/EXHIBITION_THUMBS_LIST': 'Kadr na listę',
    'T_THUMB_API/EXHIBITION_THUMBS_LISTFULLMD':
      'Kadr na listę - pełna szerokość',
    'T_THUMB_API/EXHIBITION_THUMBS_LISTFULL':
      'Kadr na listę - pełna szerokość - duże ekrany',
    'T_THUMB_API/EXHIBITION_THUMBS_LISTSQ': 'Kadr na listę kwadrat',

    'Shortcut elements': 'Sekcja "Na skróty"',
    T_MODULE_SHORTCUT: 'Na skróty',
    T_MODULE_SHORTCUT_NEW_BUTTON: 'Dodaj skrót',
    T_SHORTCUT_BLANK: 'Otwórz w nowym oknie',

    T_PAGE_SECTION_IMAGES: 'Zdjęcia',
    'T_THUMB_API/PAGE_SECTION_IMAGE_THUMBS_SMALL': 'Kadr mały',
    'T_THUMB_API/PAGE_SECTION_IMAGE_THUMBS_MEDIUM': 'Kadr średni',
    'T_THUMB_API/PAGE_SECTION_IMAGE_THUMBS_SMALL_WORKSHOP':
      'Kadr mały warsztaty',
    'T_THUMB_API/PAGE_SECTION_IMAGE_THUMBS_SMALL_GARDEN': 'Kadr mały ogrody',
    'T_THUMB_API/PAGE_SECTION_IMAGE_THUMBS_SMALL_ARCH': 'Kadr mały archiwum',
    'T_THUMB_API/PAGE_SECTION_IMAGE_THUMBS_FULL': 'Kadr pełny',
    'T_THUMB_API/PAGE_SECTION_IMAGE_THUMBS_PKR_SMALL':
      'Kadr mały (proporcje oryginalne)',
    'T_THUMB_API/PAGE_SECTION_IMAGE_THUMBS_PKR_MEDIUM':
      'Kadr średni (proporcje oryginalne)',
    'T_THUMB_API/PAGE_SECTION_IMAGE_THUMBS_PKR_FULL':
      'Kadr pełny (proporcje oryginalne)',

    'Home sections': 'Strona główna',
    T_MODULE_HOME_CONFIG_SECTION_A: 'Sekcja Rezydencja',
    T_MODULE_HOME_CONFIG_SECTION_B: 'Sekcja z cytatem',
    T_MODULE_HOME_CONFIG_SECTION_C: 'Sekcja Pałac w otoczeniu',
    T_MODULE_HOME_CONFIG_SECTION_D: 'Sekcja Natura, ogrody',
    T_MODULE_HOME_CONFIG_SECTION_E: 'Sekcja Poznaj jedną historię',
    T_MODULE_HOME_CONFIG_SECTION_F: 'Sekcja Warsztaty',
    T_MODULE_HOME_CONFIG_SECTION_G: 'Sekcja Pewnego razu w wilanowie',
    T_MODULE_HOME_CONFIG_SECTION_H: 'Sekcja Architektura',

    'Page sections': 'Sekcje strony',
    discovery_architecture: 'Slider Architektura',
    become_relaxphoto: 'Zdjęcie pod wystawami',

    'Shipping countries': 'Kraje',
    T_MODULE_SHIPPING_COUNTRIES_NEW_BUTTON: 'Dodaj nowy kraj',

    'Shipping area': 'Obszar',
    'Shipping areas': 'Obszary',
    T_MODULE_SHIPPING_AREAS_NEW_BUTTON: 'Dodaj obszar',

    'Shipping area costs': 'Koszty dostaw',
    'Weight to': 'Waga do',
    'Weight from': 'Waga od',
    T_GENERAL_RECORD_NEW_COST: 'Nowy koszt',
    T_GENERAL_RECORD_EDIT_COST: 'Edycja kosztu',
    T_GENERAL_RECORD_LIST_COSTS: 'Koszty dostaw lista',
    T_MODULE_SHIPPING_AREACOST_NEW_BUTTON: 'Dodaj nowy koszt',

    'Shipping name': 'Nazwa dostawy',
    'Shipping cost': 'Koszt dostawy',
    'Shipping types': 'Typy dostaw',
    'Shipping type': 'Typ dostawy',
    'Shipping Provider': 'Dostawca usługi',
    'Shipping provider': 'Dostawca usługi',
    'Parcel locker name': 'Paczkomat',
    'Shipping type points': 'Punkty odbioru',
    'Weight zamówienia': 'Waga zamówienia',
    T_GENERAL_RECORD_NEW_POINT: 'Nowy punkt',
    T_GENERAL_RECORD_EDIT_POINT: 'Edycja punktu',
    T_GENERAL_RECORD_LIST_POINT: 'Punkty odbioru',
    T_MODULE_SHIPPING_TYPES_NEW_BUTTON: 'Dodaj nowy typ',
    T_MODULE_SHIPPING_TYPEPOINT_NEW_BUTTON: 'Dodaj nowy punkt',

    'Payment types': 'Typy płatności',
    'Payment title': 'Typ/Nazwa płatności',
    T_MODULE_PAYMENT_NEW_BUTTON: 'Nowy typ płatności',

    Orders: 'Zamówienia',
    'Order parts': 'Szczegóły zamówienia',
    'Full address': 'Adres wysyłki',
    'Total weight': 'Całkowita waga zamówienia',
    'User email': 'Email zamawiającego',
    'First name': 'Imię',
    Surname: 'Nazwisko',
    'Comment/notice user': 'Informacja/komentarz od kupującego',

    'Discount codes': 'Kody rabatowe',
    'Discount code': 'Kod rabatowy',
    'Discount code rate': 'Zniżka kodu rabatowego',
    T_MODULE_DISCOUNT_CODE_NEW_BUTTON: 'Dodaj kod rabatowy',

    'Discount products': 'Promocje',
    T_MODULE_DISCOUNT_PRODUCT_NEW_BUTTON: 'Dodaj promocję',

    Products: 'Produkty',
    'Product categories': 'Kategorie',
    'Product category': 'Kategoria',
    T_MODULE_PRODUCT_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_MODULE_PRODUCTCATEGORY_ADD_SUBPAGE: 'Dodaj podkategorię',
    T_MODULE_PRODUCTS_NEW_BUTTON: 'Dodaj produkt',
    'Linked products': 'Powiązane produkty',
    New: 'Nowość',
    Promotion: 'Promocja',
    Recommended: 'Polecany',
    Future: 'Zapowiedź',
    Price: 'Cena',
    Quantity: 'Dostępna ilość',
    Weight: 'Waga',
    'T_THUMB_API/PRODUCT_THUMBS_VERTICAL': 'Kadr pionowy',
    'T_THUMB_API/PRODUCT_THUMBS_HORIZONTAL': 'Kadr poziomy',

    Education: 'Edukacja',
    'Education category home': 'Kategorie "w domu"',
    'Education category museum': 'Kategorie "w muzeum"',
    'Education category happen': 'Kategorie wydarzeń',
    'Education category visit': 'Kategorie w "Odwiedź nas"',
    T_NEW_CATEGORY_BUTTON: 'Dodaj kategorię',
    'EducationCategory tags': 'Tagi w kategori',

    T_ARTICLES: 'Artykuły',
    T_ARTICLES_NEW_BUTTON: 'Dodaj artykuł',

    Places: 'Miejsca',
    Place: 'Miejsce',
    'Place of meet': 'Mejsce zbiórki',
    T_MODULE_PLACES_NEW_BUTTON: 'Dodaj miejsce',

    edu_family: 'Rodziny',
    edu_guest: 'Goście',
    edu_groups: 'Grupy',
    edu_mergeoffers: 'Łącz oferty',

    'Iart Collections': 'iArt kolekcja',
    T_SYNC_ERROR: 'Błąd !',
    T_SYNC_SUCCESS: 'Sukces !',

    'Education category project': 'Kategorie',
    T_TRANSLATOR_MASS_SELECT_EXPORT: 'Eksport',
    T_TRANSLATOR_MASS_SELECT_EXPORT_DONE: 'Wyeksportowano pomyślnie',
    T_TRANSLATOR_MASS_SELECT_DELETE: 'Usuń',
    T_TRANSLATOR_MASS_SELECT_DELETE_DONE: 'Usunięto wybrane poprawnie',

    STATUS_NEW: 'Nowe zamówienie',
    STATUS_WAIT_FORPAY: 'Oczekiwanie na płatność',
    STATUS_PAID: 'Opłacone',
    STATUS_INPROGRESS: 'W realizacji',
    STATUS_SEND_PREPARE: 'Przygotowane do wysłania',
    STATUS_SEND: 'Wysłane',
    STATUS_DONE_INPOINT: 'Gotowe do odbioru w punkcie',
    STATUS_DONE: 'Gotowe',
    STATUS_ABORT: 'Anulowane',

    T_MODULE_SOBIESCIANA_NEW_BUTTON: 'Dodaj',
    Sobiescianas: 'Sobiesciana',
    'Sobiesciana filters': 'Filtry',

    T_MODULE_PASAZ_NEW_BUTTON: 'Dodaj',
    Pasazs: 'Pasaż wiedzy',

    Users: 'Użytkownicy',
    T_MODULE_USERS_NEW_BUTTON: 'Dodaj użytkownika',

    T_GENERAL_RESET_PASSWD: 'Reset hasła',
    T_GENERAL_RESET_PASSWD_CONFIRM:
      'Czy napewno chesz zresetować hasło użytkownikowi i wysłać do niego emaila z linkiem do ustawienia nowego hasła?',
    T_GENERAL_RESET_PASSWD_OK: 'Hasło zostało zresetowane poprawnie',

    'T_THUMB_API/PERSON_ITEM_THUMBS_MAIN' : 'kadr',
    'file: T_SUPPORTED_MIMES_FOR_PHOTO': 'Zły typ pliku, wybierz jpeg,jpg,webp,gif',

    'Vats': 'Stawki VAT',
    T_MODULE_VAT_NEW_BUTTON: 'Dodaj nową stawkę',
    'For who/Dla kogo': 'Dla kogo',
    'Rate' : 'Stawka VAT',
    'Area' : 'Skąd',

    '[OF] T_GENERAL_MEDIA_PHOTO': '[OF] Zdjęcia',
    'Exhibition blocks' : 'Bloki wystawy',
    T_VALIDATION_LETTERS: 'Proszę wpisać tylko litery, bez polskich znaków, cyfr i znaków specjalnych'
  },
}
